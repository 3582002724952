var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"#363636","flat":"","height":"89"}},[(_vm.drawer)?_c('v-icon',{attrs:{"square":"","large":"","color":"white"},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[_vm._v(" mdi-menu-open ")]):_c('v-icon',{attrs:{"large":"","square":"","color":"white"},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[_vm._v(" mdi-menu ")]),_c('v-toolbar-title',{staticClass:"custom_title",domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"white","min-width":"0","text":""},on:{"click":function($event){return _vm.redirectToOutOfStocks()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-numeric-0-box")])],1)]}}])}),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"white","min-width":"0","text":""},on:{"click":function($event){return _vm.redirectToSupportTicket(_vm.openTickets)}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.openTickets.length))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-ticket")])],1)],1)]}}])}),_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"white","min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.cancelOrders.length))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.cancelOrders),function(cancelOrder,i){return _c('app-bar-item',{key:("item-" + i)},[_c('app-bar-item-title',{attrs:{"width":"100%","background-color":"primary"}},[_vm._v(" Order "),_c('b',[_vm._v(" "+_vm._s(cancelOrder.orders[0].id)+" ")]),_vm._v(" has been cancelled by driver due to "),_c('b',[_vm._v("\" "+_vm._s(cancelOrder.reason)+"\"")])])],1)}),1)])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"}},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',_vm._l((_vm.vans),function(van,i){return _c('app-bar-item',{key:("item-" + i)},[_c('app-bar-item-title',{attrs:{"width":"100%","background-color":"primary"},on:{"click":function($event){return _vm.redirectToVanInventory(van)}}},[_vm._v(" Low Inventory in Van "),_c('b',[_vm._v(" "+_vm._s(van.vehicle_number)+" ")]),_vm._v(" at ZipCode "),_c('b',[_vm._v(" "+_vm._s(van.zipcode))])])],1)}),1)])],1),_c('v-btn',{attrs:{"color":"#e84500"},on:{"click":_vm.logout}},[_c('span',[_vm._v("Sign Out")]),_c('v-icon',{attrs:{"right":"","color":"white"}},[_vm._v("mdi-exit-to-app")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }